import clsx from "clsx";
import React from "react";

import { QuestionTooltip } from "./tooltip";

export type LabelProps = React.PropsWithChildren<{
  id?: string;
  required?: boolean;
  red?: boolean;
  tooltip?: React.ReactNode;
  semibold?: boolean;
  italic?: boolean;
  size?: "xl";
}> &
  LabelVariantProps;

type LabelVariantProps =
  | { readOnly?: never; htmlFor: string; as?: never }
  | { readOnly: true; htmlFor?: never; as?: never }
  | { readOnly?: never; htmlFor?: never; as: "legend" };

export function Label({
  as,
  htmlFor,
  id,
  readOnly,
  required = false,
  red = false,
  semibold = false,
  italic = false,
  size,
  children,
  tooltip,
}: LabelProps) {
  const Comp = as ?? (readOnly ? "span" : "label");

  return (
    <Comp
      id={id}
      htmlFor={htmlFor}
      className={clsx(
        "tw-mb-[5px] tw-inline-block tw-max-w-full tw-border-0",
        semibold ? "tw-font-semibold" : "tw-font-bold",
        { "tw-italic": italic },
        { "tw-text-red-600": red },
        size == "xl" ? "tw-text-[17px]" : "tw-text-sm",
      )}
    >
      {children}

      {required ? <LabelRequiredIndicator /> : null}

      {tooltip ? <QuestionTooltip>{tooltip}</QuestionTooltip> : null}
    </Comp>
  );
}

export function LabelRequiredIndicator() {
  return (
    <span
      className="tw-ml-1.5 tw-text-[17px] tw-text-[#e02222]"
      aria-required="true"
      aria-hidden="true"
    >
      *
    </span>
  );
}
